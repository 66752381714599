
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '1791'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function repeatField3(field, fieldIndex) {
                    return _createElement.apply(this, [
                        'div',
                        {
                            'className': 'fitment-cell fitment-field-' + field.toLowerCase(),
                            'key': this[field]
                        },
                        this[field] && this[field] !== 'Universal' ? _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex)) : null
                    ]);
                }
                return _createElement.apply(this, [
                    'div',
                    { 'className': 'fitment-row' },
                    _map(this.fields, repeatField3.bind(this))
                ]);
            }, { count: undefined })];
    }
    function repeatItems2(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '4441'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function repeatField3(field, fieldIndex) {
                    return [this[field] && this[field] !== 'Universal' ? _createElement('div', {
                            'className': 'fitment-row',
                            'key': this[field]
                        }, _createElement('div', { 'className': 'fitment-cell' }, window.themeSettings?.locales?.ymm[field.toLowerCase()] || field), _createElement.apply(this, [
                            'div',
                            { 'className': 'fitment-cell' },
                            _map(this[field].split('\n'), repeatFieldValue2.bind(this, field, fieldIndex))
                        ])) : null];
                }
                return itemsIndex !== 0 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'fitment-table__inner-container',
                        'key': '11'
                    },
                    _map(this.fields, repeatField3.bind(this))
                ]) : null;
            }, { count: undefined })];
    }
    return this.vehicleSpecific || this.manyFitments ? _createElement('div', {
        'className': 'cm_fitment-table',
        'key': '0'
    }, _createElement('div', { 'className': 'toggle-title' }, _createElement('a', {
        'className': 'toggleLink collapsed',
        'data-toggle': 'collapse',
        'href': '#tab-fitment_mobile'
    }, _createElement('span', { 'className': 'page-heading' }, 'Fitment'), _createElement('svg', {
        'aria-hidden': 'true',
        'focusable': 'false',
        'role': 'presentation',
        'className': 'icon icon--wide icon-chevron-up',
        'viewBox': '0 0 498.98 284.49'
    }, _createElement('path', {
        'className': 'cls-1',
        'd': 'M579.91 486.24A35 35 0 0 1 520.16 511L330.42 321.25 140.68 511a35 35 0 1 1-49.5-49.5L305.67 247a35 35 0 0 1 49.5 0l214.49 214.5a34.89 34.89 0 0 1 10.25 24.74z',
        'transform': 'translate(-80.93 -236.76)'
    })))), _createElement('div', {
        'className': 'toggle-content collapse',
        'id': 'tab-fitment_mobile'
    }, _createElement('div', { 'className': 'fitment-table-container' }, this.vehicleSpecific ? _createElement('div', {
        'className': 'fitment-table-title vehicle-specific',
        'key': '757'
    }) : null, this.manyFitments ? _createElement('div', {
        'className': 'fitment-table-title many-fitments',
        'key': '865'
    }, '\n        Currently displaying 1000 fitments, yet the product has additional fitments.\n      ') : null, this.template === 'horizontal' ? _createElement('div', {
        'className': 'cm_fitment-table__horizontal cmTemplate_horizontal',
        'key': '1043'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])) : null, this.template === 'vertical' ? _createElement('div', {
        'className': 'cm_fitment-table__vertical cmTemplate_vertical',
        'key': '2689'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems2.bind(this))
    ])) : null))) : null;
}
        export const componentNames = []